body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root {
    width: 100%;
    height: 100%;
}